import React, { useState } from 'react'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'

import { Headline4 } from './Typography'
import AnimatedThumbCursor from './AnimatedThumbCursor'
import Marquee from 'react-fast-marquee'

const slidingPillTexts = [
  [
    'ERP & PIM integrations',
    'Product bundling',
    'WMS & 3PL implementations',
    'Custom checkout',
  ],
  [
    'Subscriptions',
    'Server load balancing and optimization',
    'Favorites and wishlists',
    'Server infrastructure',
  ],
  [
    'Global ecommerce',
    'Dynamic search, filtering and sorting',
    'Deployment strategies',
    'Analytics integrations',
  ],
  [
    'Referral and rewards',
    'Frontend setup and build',
    'ROPIS, BOPIS, Click and collect',
    'Replatforming',
  ],
]

const SlidingPillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: none;
  overflow: hidden;
`

const SlidingPillRow = styled.div`
  max-width: 100vw;
  overflow: hidden;

  ${mq({
    marginBottom: ['16px', '16px', '36px', '36px', '36px'],
  })};
`

const SlidingPillMarquee = styled(Marquee)`
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`

const SlidingPill = styled.div`
  ${mq({
    padding: [
      '16px 32px',
      '16px 32px',
      '23px 48px 25px',
      '23px 48px 25px',
      '23px 48px 25px',
    ],
    margin: ['0 8px', '0 8px', '0 18px', '0 18px', '0 18px'],
  })};
  border: 1px solid #c7c7c6;
  box-sizing: border-box;
  border-radius: 56px;
  padding: 23px 48px 25px;
  display: inline-block;
  transition: all 0.3s ease;

  &:hover {
    background: var(--screen-500);
    border: 1px solid var(--screen-500);
  }
`

const SlidingPillHeadline = styled.div`
  ${Headline4}
  color: var(--terminal-500);

  ${SlidingPill}:hover & {
    color: var(--terminal-0);
  }
`

function AboutSlidingPills() {
  const [hovered, setHovered] = useState(false)

  return (
    <SlidingPillWrapper id="pills">
      {slidingPillTexts.map((text, index) => (
        <SlidingPillRow key={index}>
          <SlidingPillMarquee
            pauseOnHover={true}
            gradient={false}
            direction={index % 2 ? 'left' : 'right'}
            speed={40}
          >
            {text.concat(text).map((pillText, index) => (
              <SlidingPill
                key={index}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <SlidingPillHeadline>{pillText}</SlidingPillHeadline>
              </SlidingPill>
            ))}
          </SlidingPillMarquee>
        </SlidingPillRow>
      ))}
      <AnimatedThumbCursor hovered={hovered ? 1 : undefined} />
    </SlidingPillWrapper>
  )
}

export default AboutSlidingPills
