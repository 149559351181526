import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { Link } from 'gatsby'

import HeroPageTemplate from '../templates/hero-page'
import Seo from '../components/seo'
import Gallery from '../components/Gallery'
import ContactForm from '../components/ContactForm'
import ServicesSlidingPills from '../components/ServicesSlidingPills'

import SolidusSVG from '../assets/logo/solidus-icon.svg'
import RailsSVG from '../assets/logo/rails-logo.svg'
import ReactSVG from '../assets/logo/react-logo.svg'

import { Subheading1, Headline4, Body2 } from '../components/Typography'

import Layout from '../components/Layout'

const SolidusLogo = props => {
  const StyledSolidus = styled(SolidusSVG)`
    width: 32px;
    height: 32px;
    display: block;
    margin-bottom: 16px;

    path {
      fill: var(--terminal-500);
    }
  `

  return <StyledSolidus {...props} />
}

const RailsLogo = props => {
  const StyledRails = styled(RailsSVG)`
    width: 32px;
    height: 32px;
    display: block;
    margin-bottom: 16px;

    path {
      fill: var(--terminal-500);
    }
  `

  return <StyledRails {...props} />
}

const ReactLogo = props => {
  const StyledReact = styled(ReactSVG)`
    width: 32px;
    height: 32px;
    display: block;
    margin-bottom: 16px;

    path {
      fill: var(--terminal-500);
    }
  `

  return <StyledReact {...props} />
}

const BackgroundWrapper = styled.div`
  background: #e5e5e5;
`

const GalleryPlaceholder = styled.div`
  background: #e5e5e5;
  height: 480px;
  width: 100vw;
`

const Spacer = styled.div`
  ${mq({
    height: ['120px', '120px', '148px', '148px', '148px'],
  })};
`

const SubHeader = styled.div`
  ${Subheading1}
  color: var(--terminal-400);
  ${mq({
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
    margin: ['92px 0 120px 0'],
  })};

  a {
    color: var(--screen-500);
    text-decoration: none;
  }
`

const TextColumnWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
    columnCount: ['1', '3', '3', '3', '3'],
    marginBottom: ['160px', '160px', '200px', '240px', '240px'],
  })};
  column-rule-style: solid;
  column-rule-width: 1px;
  column-rule-color: var(--terminal-100);
  column-gap: 72px;
`

const ColumnTitle = styled.div`
  ${Headline4};
  color: var(--terminal-500);
  margin-bottom: 16px;
`

const ColumnBody = styled.div`
  ${Body2};
  color: var(--terminal-400);
  break-inside: avoid-column;
  column-span: 1;
`

const ColumnHorizontalLine = styled.hr`
  border: 1px solid var(--terminal-100);
  margin: 24px 0;
  ${mq({
    display: ['block', 'none', 'none', 'none', 'none'],
  })};
`

const ColumnLink = styled(Link)`
  ${Body2};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  margin-top: 16px;
  display: block;
`

const ContactFormWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '2', '2'],
    gridColumnEnd: ['5', '9', '12', '12', '12'],
  })};
`

function Services() {
  const [windowResizing, setWindowResizing] = useState(false)
  const [contactFormRef, contactFormInView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  })
  const pageTitle = 'Our Services'

  useEffect(() => {
    let timeout
    const cachedWidth = window.innerWidth

    const handleResize = () => {
      clearTimeout(timeout)

      if (cachedWidth !== window.innerWidth) {
        setWindowResizing(true)
      }

      timeout = setTimeout(() => {
        setWindowResizing(false)
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const firstLine =
    'We build, scale and maintain enterprise ecommerce software.'
  const secondLine =
    'Whatever stage your project is in, we’re ready to get to work.'

  return (
    <BackgroundWrapper>
      <HeroPageTemplate
        theme={'day'}
        firstLine={firstLine}
        secondLine={secondLine}
        title="Services"
        copyrightYear={'2022'}
      >
        <Seo
          title={pageTitle}
          description="We build new digital storefronts, maintaining legacy applications, and everything in between."
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
        />
        <Layout>
          <SubHeader>
            We have years of ecommerce development experience, building scalable
            solutions with technologies that push the industry forward.
          </SubHeader>
          <TextColumnWrapper>
            <ColumnBody>
              <SolidusLogo />
              <ColumnTitle>Solidus</ColumnTitle>The core of our capabilities.
              The most flexibile, customizable ecommerce solution for
              high-volume stores.
              <ColumnLink to="/services/solidus">
                More about Solidus →
              </ColumnLink>
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <RailsLogo />
              <ColumnTitle>Ruby on Rails</ColumnTitle>Our love language. For
              leveraging scalable platforms designed for productivity and
              accommodating heavy traffic.
              <ColumnLink to="/services/ruby-on-rails">
                How we use Rails →
              </ColumnLink>
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <ReactLogo />
              <ColumnTitle>React</ColumnTitle>For rich, intuitive user
              experiences from single-page web applications to responsive
              shopping experiences.
              <ColumnLink to="/services/react">
                Why React is awesome →
              </ColumnLink>
            </ColumnBody>
          </TextColumnWrapper>
        </Layout>
        <ServicesSlidingPills />
        <Spacer />
        <Layout>
          <SubHeader>
            Beyond our software development services, we can support you during
            the build and grow phases of your business.
          </SubHeader>
          <TextColumnWrapper>
            <ColumnBody>
              <ColumnTitle>Team education and training</ColumnTitle>
              Elevate your development team by giving them access to expert
              training and workshops.
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <ColumnTitle>Technical project management</ColumnTitle>
              Get CTO-level help without the overhead of bringing a full-time
              CTO on staff.
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <ColumnTitle>Project recovery</ColumnTitle>
              Sometimes projects get stalled. Sometimes they catch fire. We help
              get them back on track.
            </ColumnBody>
          </TextColumnWrapper>
        </Layout>
        {windowResizing ? <GalleryPlaceholder /> : <Gallery />}
        <Spacer />
        <Layout>
          <SubHeader>
            Whether you’re improving your current business or launching a new
            concept from the ground up, we’re here for it.{' '}
            <Link to="/contact">Let’s talk.</Link>
          </SubHeader>
          <ContactFormWrapper ref={contactFormRef}>
            {contactFormInView ? <ContactForm /> : null}
          </ContactFormWrapper>
        </Layout>
      </HeroPageTemplate>
    </BackgroundWrapper>
  )
}

export default Services
